import {Box, Flex, Text, HStack, useDisclosure, Button} from '@chakra-ui/react'
import {map, get, snakeCase} from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import i18next from 'i18next'
import useSWR from 'swr'

import {AuthProvider} from '../../contexts/authentication'
import {useRails} from '../../contexts/rails'
import InvoiceDrawer from './InvoiceDrawer'
import InvoiceFilter, {preFilterQuery} from './InvoiceFilter'
import OverdueInvoicesPopup from './OverdueInvoicesPopup'
import CreditNotesBanner from './CreditNotesBanner'
import InvoiceActionButton from './InvoiceActionButton'
import SupplierLayout from '../../layouts/SupplierLayout'
import useFeatureCookie from '../../hooks/useFeatureCookie'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import {
  DataTable,
  EmptyList,
  FilterEmptyList,
  InvoiceCashedInCell,
  InvoiceDueDateCell,
  InvoiceDueAmountCell,
  InvoicePaymentStatusCell,
  TruncatedCell,
  Pagination,
} from '../../components'
import {
  toCurrency,
  toLocalDate,
  toEpochMillisecond,
} from '../../../utils/formatter'
import Feedback from '../../components/Feedback'

const PER_PAGE = 25

const columns = [
  {header: 'Issue date', accessorKey: 'issueDate'},
  {header: 'Customer', accessorKey: 'contactName', cell: TruncatedCell},
  {header: 'Invoice', accessorKey: 'number', cell: TruncatedCell},
  {header: 'Amount', accessorKey: 'total'},
  {header: 'Cash-in', accessorKey: 'cashinStatus', cell: InvoiceCashedInCell},
  {
    header: 'Status',
    accessorKey: 'paymentStatus',
    cell: InvoicePaymentStatusCell,
  },
  {header: 'Due date', accessorKey: 'dueDate', cell: InvoiceDueDateCell},
  {header: 'Due amount', accessorKey: 'dueAmount', cell: InvoiceDueAmountCell},
]

export const SupplierInvoiceScreen = ({authenticityToken}) => {
  const {organisation} = useRails()

  i18next.loadNamespaces('invoicesPage')

  const daysPastMostOverdueInvoiceDueDate = get(
    organisation,
    'currentSupplier.daysPastMostOverdueInvoiceDueDate',
    0,
  )

  const [overdueInvoicesBannerDisabled, disableOverdueInvoicesBanner] =
    useFeatureCookie('oib_disabled')
  const {
    params: preFilterParams,
    activeFilters: preActiveFilters,
    filterOption: preFilterOption,
  } = preFilterQuery(window.location.search)

  const [filterParams, setFilterParams] = React.useState(preFilterParams || '')
  const [filterOption, setFilterOption] = React.useState(preFilterOption || {})
  const [activeFilters, setActiveFilters] = React.useState(
    preActiveFilters || 0,
  )

  const [page, setPage] = React.useState(1)
  React.useEffect(() => setPage(1), [filterParams])

  const [sortBy, setSortBy] = React.useState(null)
  const [orderBy, setOrderBy] = React.useState(null)
  const handleOnSortTable = ([sort]) => {
    if (!sort) {
      setSortBy(null)
      setOrderBy(null)
    } else {
      if (sort.id === 'canAddOverdueInfo') {
        return
      }

      setSortBy(snakeCase(sort.id))
      setOrderBy(sort.desc ? 'desc' : 'asc')
    }
  }

  const constructUrl = React.useMemo(() => {
    let url = `/api/invoices?page=${page}&per_page=${PER_PAGE}`

    if (filterParams) url = `${url}&${filterParams}`
    if (sortBy) url = `${url}&sort_by=${sortBy}`
    if (orderBy) url = `${url}&order_by=${orderBy}`
    return url
  }, [page, filterParams, sortBy, orderBy])

  const {data, error, mutate} = useSWR(constructUrl)

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  const totalCount = get(data, 'metadata.total_count', 0)
  const totalPages = get(data, 'metadata.total_pages', 0)

  const invoices = map(get(data, 'invoices'), (invoice) => ({
    contactName: `${invoice.contact_name}`,
    customerId: invoice.customer_id,
    number: `${invoice.number}`,
    total: toCurrency(invoice.total.cents),
    dueAmount: toCurrency(invoice.due_amount.cents),
    issueDate: toLocalDate(toEpochMillisecond(invoice.issue_date)),
    dueDate: invoice.due_date,
    overDue: invoice.over_due,
    overDueDays: invoice.over_due_days,
    cashinStatus: invoice.accelerated,
    paymentStatus: invoice.payment_status,
    id: invoice.id,
    xeroInvoiceUuid: invoice.xero_invoice_uuid,
    canAddOverdueInfo: invoice.can_add_overdue_info,
    canReissue: invoice.can_reissue,
    reissuedAt: invoice.reissued_at,
    due_amount: invoice.due_amount,
    due_date: invoice.due_date,
    creditNoteApplications: invoice.credit_note_applications,
  }))

  const invoiceOptions = map(
    get(data, 'invoice_number_options'),
    (invoice) => ({
      label: invoice.xero_invoice_number,
      value: invoice.id,
      customerId: invoice.customer_id,
      contactName: invoice.contact_name,
    }),
  )

  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()

  const overdueInvoiceInfoEnabled = features && features.overdueInvoiceInfo
  const creditNotesEnabled = features && features.creditNotes
  const loading = (!data && !error) || loadingFeatures
  const emptyList = !invoices.length && !loading && !activeFilters
  const emptyFilteredList = !invoices.length && !loading && activeFilters

  const showOverdueInvoicesPopup =
    daysPastMostOverdueInvoiceDueDate >= 25 &&
    overdueInvoiceInfoEnabled &&
    !overdueInvoicesBannerDisabled

  const invoiceDrawer = useDisclosure()
  const [selectedInvoice, setSelectedInvoice] = React.useState(null)
  const showInvoice = (invoice) => {
    if (!invoice) return

    setSelectedInvoice(invoice)
    invoiceDrawer.onOpen()
  }
  if (data && filterOption.invoice?.value && !selectedInvoice) {
    showInvoice(invoices[0])
  }

  const disabledButtonProps = {
    fontWeight: 'medium',
    backgroundColor: 'neutral.dust',
    color: 'primary.actionblue',
    cursor: 'not-allowed',
    borderColor: 'primary.actionblue',
    _hover: {
      backgroundColor: 'neutral.dust',
      color: 'primary.actionblue',
    },
  }

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Invoices">
        {selectedInvoice && (
          <InvoiceDrawer
            invoice={selectedInvoice}
            isOpen={invoiceDrawer.isOpen}
            onClose={invoiceDrawer.onClose}
            mutateList={mutate}
          />
        )}

        <Box display="flex" flexDirection="column" height="100vh">
          <HStack
            justifyContent="space-between"
            px="md"
            pb="xs"
            pt="sm"
            borderColor="neutral.glitter"
          >
            <Text fontSize="26px" fontWeight="medium">
              Invoices
            </Text>
            <InvoiceFilter
              invoiceOptions={invoiceOptions}
              activeFilters={activeFilters}
              filterOption={filterOption}
              setFilterParams={setFilterParams}
              setFilterOption={setFilterOption}
              setActiveFilters={setActiveFilters}
            />
          </HStack>
          <Feedback px="md" />

          <Box flexGrow="1" overflow="scroll" whiteSpace="nowrap">
            {error && (
              <Text ml="md">
                Unable to fetch invoices, please try again later.
              </Text>
            )}

            {creditNotesEnabled && <CreditNotesBanner m="md" />}

            {showOverdueInvoicesPopup && (
              <Box px="md" whiteSpace="normal" position="sticky" left="0">
                <OverdueInvoicesPopup
                  overdueDays={daysPastMostOverdueInvoiceDueDate}
                  disableOverdueInvoicesBanner={disableOverdueInvoicesBanner}
                />
              </Box>
            )}

            {daysPastMostOverdueInvoiceDueDate > 25 && (
              <Flex py="sm" px="md" position="sticky" flexWrap="wrap" gap="sm">
                <Text fontWeight="medium" my="auto">
                  Filter:
                </Text>

                <Button
                  data-testid="all-invoices-link"
                  variant="outline"
                  textStyle="body-small"
                  isDisabled={filterParams === ''}
                  _disabled={disabledButtonProps}
                  onClick={() => {
                    window.location.search = ''
                  }}
                >
                  All invoices
                </Button>

                <Button
                  data-testid="twenty-link"
                  variant="outline"
                  textStyle="body-small"
                  isDisabled={
                    filterParams ===
                    'days_past_due_date=25&cashin_status=true&payment_status=unpaid'
                  }
                  _disabled={disabledButtonProps}
                  onClick={() => {
                    window.location.search =
                      'days_past_due_date=25&cashin_status=true&payment_status=unpaid'
                  }}
                >
                  25 days overdue (Cashed-in)
                </Button>

                {daysPastMostOverdueInvoiceDueDate > 33 && (
                  <Button
                    data-testid="thirty-three-link"
                    variant="outline"
                    textStyle="body-small"
                    isDisabled={
                      filterParams ===
                      'days_past_due_date=33&cashin_status=true&payment_status=unpaid'
                    }
                    _disabled={disabledButtonProps}
                    onClick={() => {
                      window.location.search =
                        'days_past_due_date=33&cashin_status=true&payment_status=unpaid'
                    }}
                  >
                    33 days overdue (Cashed-in)
                  </Button>
                )}
              </Flex>
            )}

            <DataTable
              columns={[
                ...columns,
                {
                  header: '',
                  accessorKey: 'actionButton',
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cell: ({row}) => (
                    <InvoiceActionButton
                      invoice={row.original}
                      mutate={mutate}
                    />
                  ),
                },
              ].filter(Boolean)}
              data={invoices}
              isLoading={loading}
              onClickRow={showInvoice}
              onSort={handleOnSortTable}
              enableSorting
              manualSorting
            />

            {emptyList && <EmptyList resourceName="invoices" />}

            {emptyFilteredList && <FilterEmptyList resourceName="invoices" />}

            {!emptyFilteredList && (
              <Box px="md" mb="md" position="sticky" left="0">
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  totalCount={totalCount}
                  perPage={PER_PAGE}
                  onPageChange={setPage}
                />
              </Box>
            )}
          </Box>
        </Box>
      </SupplierLayout>
    </AuthProvider>
  )
}

SupplierInvoiceScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default withProviders(withSWR(SupplierInvoiceScreen))
