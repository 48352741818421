import * as Yup from 'yup'
import Dinero from 'dinero.js'
import isValidABN from 'is-valid-abn'
import {useTranslation} from 'react-i18next'
import {camelize} from 'humps'
import {useRails} from '../../../contexts/rails'
import usePaymentMethods from './usePaymentMethods'

export default function useValidationSchema() {
  const {t} = useTranslation('billPayments')

  const i18nPrefix = 'billPayments:billPayment.form'
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
  } = useRails()
  const {findPaymentMethod} = usePaymentMethods(supplierId)

  const findAndParsePaymentMethod = (value) => {
    const paymentMethod = findPaymentMethod(value)

    if (!paymentMethod) return {}

    return paymentMethod[camelize(paymentMethod.type)]
  }

  const validationSchema = Yup.object().shape({
    paymentMethodId: Yup.string()
      .test({
        name: 'withinPaymentLimit',
        test: (value, {createError}) => {
          const {dailyPaymentCount, dailyPaymentLimit} =
            findAndParsePaymentMethod(value)

          if (!dailyPaymentLimit || !dailyPaymentCount) return true

          return (
            dailyPaymentCount < dailyPaymentLimit ||
            createError({
              message: t(`${i18nPrefix}.fields.paymentMethod.limitError`, {
                limit: dailyPaymentLimit,
              }),
            })
          )
        },
      })
      .required(t(`${i18nPrefix}.fields.paymentMethod.requiredError`)),
    payeeName: Yup.string().required(
      t(`${i18nPrefix}.fields.payeeName.requiredError`),
    ),
    payeeEmail: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t(`${i18nPrefix}.fields.payeeEmail.emailFormatError`),
      )
      .required(t(`${i18nPrefix}.fields.payeeEmail.requiredError`)),
    businessNumber: Yup.string()
      .test(
        'validAbn',
        t(`${i18nPrefix}.fields.businessNumber.invalidError`),
        (value) => isValidABN(value),
      )
      .required(t(`${i18nPrefix}.fields.businessNumber.requiredError`)),
    amount: Yup.number()
      .min(1_00, t(`${i18nPrefix}.fields.amount.minError`))
      .test({
        name: 'max',
        test: (value, {createError, parent: {paymentMethodId}}) => {
          // If they have not selected a payment method yet then we can't check if it falls within the limit
          if (!paymentMethodId) return true

          const {maximumPaymentAmount} =
            findAndParsePaymentMethod(paymentMethodId)

          // If the selected payment method has no maximum payment amount, return true
          if (!maximumPaymentAmount) return true

          const dineroValue = new Dinero({
            amount: value,
            currency: maximumPaymentAmount.getCurrency(),
          })

          return (
            dineroValue.lessThanOrEqual(maximumPaymentAmount) ||
            createError({
              message: t(`${i18nPrefix}.fields.amount.maxError`, {
                maxAmount: maximumPaymentAmount.toFormat(),
              }),
            })
          )
        },
      })
      .required(t(`${i18nPrefix}.fields.amount.requiredError`)),
    branchCode: Yup.string()
      .matches(/^\d{6}$/, t(`${i18nPrefix}.fields.branchCode.lengthError`))
      .required(t(`${i18nPrefix}.fields.branchCode.requiredError`)),
    accountNumber: Yup.string()
      .matches(/^\d{6,9}$/, t(`${i18nPrefix}.fields.accountNumber.lengthError`))
      .required(t(`${i18nPrefix}.fields.accountNumber.requiredError`)),
    reference: Yup.string()
      .max(9, t(`${i18nPrefix}.fields.reference.maxError`))
      .required(t(`${i18nPrefix}.fields.reference.requiredError`)),
  })

  return {
    validationSchema,
  }
}
